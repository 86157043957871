<template>
	<div>
		<div class="headerWrap">
			<ShareHeader :title='title'></ShareHeader>
			<div class="headerCon">
				<img :src="host+info.logo" style="width: 100px;height: 100px;">
				<div style="margin-left: 26px;">
					<div style="color: #fff;font-size: 16px;font-weight: bold;">{{info.name}}</div>
					<div v-if="type==2" style="color: #fff;font-size: 12px;margin-top: 16px;">直播号：{{info.live_telecast}}
					</div>
					<img v-if="type==2" src="../../assets/image/liveBroadcast/gozbc.png"
						style="width: 107px;height: 24px;margin-top: 23px;" @click="goUrl">
					<div style="color: #fff;font-size: 12px;margin-top: 16px;" v-if="type==1">{{info.description}}</div>
				</div>
			</div>
		</div>
		<div class="nav">
			<div @click="selectNav(item,index)" :class="selectNavIndex==index?'selectColor':'noColor'"
				v-for="(item,index) in navList" :key='index'>
				{{item}}
			</div>
		</div>
		<div v-if="selectNavIndex==0" class="navContent">
			<div style="color: #434444;font-size: 16px;font-weight: bold;">品牌简介</div>
			<div style="margin-top: 7px;" v-html="info.introduction"></div>
		</div>
		<div v-if="selectNavIndex==1" class="navContent">
			<!-- <div style="position: relative;">
				<img v-if="!videoShow" @click="videoShow = true" src="../../assets/image/liveBroadcast/zwt.png"
					style="width: 100%;height: 420px;">
				<img v-if="!videoShow" @click="videoShow = true" src="../../assets/image/liveBroadcast/zt.png"
					class="videoShow">
			</div>
			<video autoplay @click="videoShow" v-if="videoShow" controls poster="" id="videoDetails"
				src="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4"></video> -->
			<div v-html="info.story"></div>
		</div>
	</div>
</template>

<script>
	import {
		host
	} from '../../utils/host.js'
	import {
		contentInfo
	} from '../../api/home.js'
	import ShareHeader from '@/components/shareHeader.vue'
	export default {
		name: "brandDetail",
		data() {
			return {
				host: host,
				navList: [],
				selectNavIndex: 0,
				videoShow: false,
				type: '',
				title: '',
				id: '',
				info: {},
			}
		},
		components: {
			ShareHeader
		},
		created() {
			this.type = this.$route.query.type
			if (this.type == 1) {
				this.title = '雪场信息'
			} else if (this.type == 2) {
				this.title = '商户信息'
			}
			this.id = this.$route.query.id
			this.setNav()
			this.getInfo()
		},
		methods: {
			goUrl() {
				window.location.href = this.info.live_broadcast
			},
			getInfo() {
				let data = new FormData()
				data.append('id', this.id)
				contentInfo(data).then(res => {
					console.log('详情', res)
					this.info = res.data
				})
			},
			setNav() {
				let list
				if (this.type == 1) {
					list = ['品牌简介', '品牌故事', '抽奖活动']
				}
				if (this.type == 2) {
					list = ['品牌简介', '品牌故事', '直播']
				}
				this.navList = list
			},
			selectNav(item, index) {
				if (index == 2 && this.type == 2) {
					this.$router.push({
						path: '/liveBroadcast'
					})
					return
				}
				if (index == 2 && this.type == 1) {
					this.$router.push({
						path: '/luckDraw'
					})
					return
				}
				this.selectNavIndex = index
			}
		}
	}
</script>

<style scoped>
	.headerWrap {
		width: 100%;
		background: #DD1C25;
		height: 200px;
		padding-top: 30px;
		box-sizing: border-box;
	}

	.headerCon {
		margin: 26px 30px 0 30px;
		display: flex;
		align-items: center;
	}

	.nav {
		margin: 17px;
		padding: 0 14px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.noColor {
		color: #86838C;
		font-size: 14px;
	}

	.selectColor {
		color: #434444;
		font-size: 14px;
		position: relative;
	}

	.selectColor::after {
		content: '';
		width: 100%;
		height: 3px;
		background: #DD1C25;
		border-radius: 2px;
		position: absolute;
		left: 48%;
		transform: translate(-50%, 50%);
		bottom: -10%;
	}

	.navContent {
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		margin: 0 16px;
		padding: 16px;
		box-sizing: border-box;
	}

	#videoDetails {
		width: 100%;
	}

	.videoShow {
		width: 72px;
		height: 72px;
		position: absolute;
		top: 40%;
		left: 40%;
	}
</style>

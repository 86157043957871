<template>
	<div class="top" style="">
		<div @click="back">
			<img src="../assets/image/login/back.png" style="width: 11px;height: 18px;">
		</div>
		<div style="color: #434444;font-size: 16px;font-weight: bold;">{{title}}</div>
		<div class="">
			<img src="../assets/image/liveBroadcast/share.png" style="width: 18px;height: 18px;">
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		data() {
			return {

			}
		},
		props: {
			title: {
				type: String,
				default: ''
			},
		},
		methods: {
			back() {
				this.$router.go(-1)
			},
			goUrl() {
				this.$emit('preservation')
			}
		}
	}
</script>

<style scoped>
	.top {
		padding:0 0.5rem 0 0.5rem;
		box-sizing: border-box;
		background: #DD1C25;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>
